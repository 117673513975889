import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import breakpoints from "../utils/breakpoints";

const StyledH1 = styled.h1`
  width: 100%;
  margin: 0;
  padding: 0;
`;

const StyledLink = styled(Link)`
  width: 100%;
  text-align: center;
  margin: 0;
  :link,
  :hover,
  :visited,
  :active {
    color: #111111;
  }
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
  padding: 1rem;
  display: block;
  @media ${breakpoints.mobileL} {
    padding-bottom: 2rem;
  }
`;

export default function SiteTitle() {
  return (
    <StyledH1>
      <StyledLink to="/" activeStyle={{ pointerEvents: "none" }}>
        //&nbsp;laurynasl
      </StyledLink>
    </StyledH1>
  );
}
