import React from "react";
import styled from "@emotion/styled";

const StyledCopyright = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  margin-top: 3rem;
  text-align: center;
`;

export default function Copyright() {
  return <StyledCopyright>© 2020</StyledCopyright>;
}
