import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import SiteTitle from "./SiteTitle";
import Copyright from "./Copyright";
import { Helmet } from "react-helmet";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentContainer = styled.div`
  max-width: 800px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: calc(100vw - 1rem);
`;

type Props = {
  children: ReactNode;
  title: string | null;
};

export default function MainLayout({ children, title }: Props) {
  return (
    <MainContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>laurynasl{title ? ` | ${title}` : ""}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
      </Helmet>
      <SiteTitle />
      <ContentContainer>{children}</ContentContainer>
      <Copyright />
    </MainContainer>
  );
}
